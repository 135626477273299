import React, { useContext } from 'react';
import AwayTimes from '../components/esp/AwayTimes';
import { AuthContext } from '../AuthProvider';

const Controlunits = () => {

    const { esp, espLoading} = useContext(AuthContext);

    return (
        <div className="detail-container">
            {!espLoading && !esp && <p>Du har ingen värmestyrningsenhet kopplad till ditt kundkonto.</p>}
                        {/* Värmestyrning av eller på */}
                        {esp && (
            <AwayTimes/>)}
        </div>
    )
}

export default Controlunits;