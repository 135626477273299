import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom'
import toast from "react-hot-toast";
import { Parse } from "parse";

const ERROR_MESSAGES = {
    "auth.invalid-email": "Ej korrekt e-postadress",
};

const RequestPasswordReset = () => {
    const navigate = useNavigate();
    const [email, setEmail] = useState('');

    const onSubmit = (e) => {
        e.preventDefault();
        // const opts = {
        //     appName : "Geniel",
        //     publicServerURL : "",
        //     emailAdapter: ""
        // }
        Parse.User.requestPasswordReset(email)
            .then(() => {
                // Signed in
                toast.success("Återställningsmail har skickats till angiven adress");
                navigate("/login")
            })
            .catch((error) => {
                console.log("errorcode: " + error.code);
                console.log("error: " + JSON.stringify(error));
                const message = error.code
                    ? ERROR_MESSAGES[error.code.replace(/\//g, ".")]
                    : undefined;

                toast.error(message ? message : "Ett okänt fel har uppstått");
                setEmail("");
            });

    }

    return (
        <div className="login-container">
            <div className="loginform lightform">
                <div className="logininput">
                    <label htmlFor="email-address">
                        E-postadress
                    </label>
                    <input
                        id="email-address"
                        name="email"
                        type="email"
                        required
                        placeholder="E-postadress"
                        onChange={(e) => setEmail(e.target.value)}
                    />
                </div>

                <div className="logininput">
                    <button className="loginbutton"
                        onClick={onSubmit}
                        disabled={email ? false : true}
                    >
                        Återställ lösenord
                    </button>
                </div>
            </div>
        </div>

    )
}

export default RequestPasswordReset;