import { Collapse, Spinner } from "react-bootstrap";
import React, { useState, useContext } from "react";
import { AuthContext } from "../AuthProvider";
import './heating.css';
import HotWaterTimes from "../components/hotwater/HotWaterTimes";
import AutomationStatus from "../components/hotwater/AutomationStatus";
import HotWaterStart from "../components/hotwater/HotWaterStart";
import HotWaterSchedule from "../components/hotwater/HotWaterSchedule";

const Hotwater = () => {

    const { esp, espLoading } = useContext(AuthContext);

    const [tOpen, setTOpen] = useState(true);


    return (
        <div className="detail-container">
            <div className="card p-3 mb-3">
                <div className="d-flex justify-content-between align-items-center" onClick={() => setTOpen(!tOpen)}>
                    <div className="d-flex align-items-center">
                        {espLoading && (<Spinner animation="border" role="status"></Spinner>)}
                        {!espLoading && !esp && (<h5>Ingen enhet kopplad</h5>)}
                        {esp && (<h5 className="mb-0">{esp.hotWater?.automationStatus ? (<i className="bi bi-building-fill-check text-success" />) : (<i className="bi bi-building-fill-slash text-muted" />)}&nbsp;&nbsp;Varmvattenstyrning {esp.customerChosenName}</h5>)}
                    </div>
                    <i
                        className={tOpen ? "bi bi-caret-up" : "bi bi-caret-down"}
                        style={{ cursor: "pointer" }}
                    />
                </div>
                <Collapse in={tOpen}>
                    <div className="mt-3">
                        {!espLoading && !esp && <p>Du har ingen styrenhet kopplad till ditt kundkonto.</p>}
                        {esp && (
                            <>
                                <AutomationStatus />
                                <HotWaterStart/>
                                <HotWaterTimes />
                                <HotWaterSchedule />
                            </>
                        )}
                    </div>
                </Collapse>
            </div>
        </div >

    )
}

export default Hotwater;


