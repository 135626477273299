import React, { useState, useEffect, useContext } from "react";
import toast from "react-hot-toast";
import { Form, Button, Collapse } from "react-bootstrap";
import { AuthContext } from "../../AuthProvider";

const ERROR_MESSAGES = {};

const UserSettings = () => {
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [open, setOpen] = useState(false);

  const {user} = useContext(AuthContext);

  useEffect(() => {
    async function fetchData(user) {
      setName(user.get('name'));
      setPhone(user.get('phone'));
    }

    if (user) {
      fetchData(user);
    }
  }, [user]);

  const onSubmit = async (e) => {
    e.preventDefault();

    user.set("name", name);
    user.set("phone", phone);
    await user.save()
      .then(async () => {
          toast.success("Användaruppgifter uppdaterade");
          setOpen(false);
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.error(errorCode, errorMessage);
        const message = error.code
          ? ERROR_MESSAGES[error.code]
          : undefined;

        toast.error(message ? message : "Ett okänt fel har uppstått");
      });
  };

  return (
    <div className="card p-3 mb-3">
      <div className="d-flex justify-content-between align-items-center" onClick={() => setOpen(!open)}>
        {name ? (
          <div>
            <h5>Kontaktuppgifter</h5>
          </div>
        ) : (
          <div>
            <h5>Kontaktuppgifter</h5>
            Uppgifter kan tyvärr inte visas just nu, försök igen om en stund
            eller kontakta support
          </div>
        )}

        <i
          className={open ? "bi bi-caret-up" : "bi bi-caret-down"}
          style={{ cursor: "pointer" }}
        />
      </div>
      <Collapse in={open}>
        <div className="mt-3">
          <Form>
            <Form.Group controlId="formName">
              <Form.Label>Namn</Form.Label>
              <Form.Control
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </Form.Group>
            <Form.Group controlId="formPhone" className="mt-2">
              <Form.Label>Telefonnummer</Form.Label>
              <Form.Control
                type="text"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
              />
            </Form.Group>
            <Button variant="primary" className="mt-3" onClick={onSubmit}>
              Spara
            </Button>
          </Form>
        </div>
      </Collapse>
    </div>
  );
};

export default UserSettings;
