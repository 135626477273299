import ReactDOM from "react-dom/client";
import "./index.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import React from 'react';
import App from "./App";
import { BrowserRouter } from "react-router-dom";

import Parse from "parse";

Parse.initialize(process.env.REACT_APP_PARSE_APP_ID, "");
Parse.serverURL = process.env.REACT_APP_PARSE_SERVER_URL;

ReactDOM.createRoot(document.getElementById('root')).render(
  <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>
);