import { Form, Modal } from "react-bootstrap";
import React, { useState, useContext } from "react";
import { AuthContext } from "../../AuthProvider";
import '../../routes/heating.css';
import Parse from "parse";

const AutomationStatus = () => {

    const { esp, setUserEsp } = useContext(AuthContext);

    const [showHelp, setShowHelp] = useState(false);

    const handleCloseHelp = () => setShowHelp(false);

    const handleShowHelp = () => setShowHelp(true);

    // Skicka kommando för att slå på/av automation
    const toggleAutomation = () => {
        const automationStatus = !esp.heating?.automationStatus;
        Parse.Cloud.run("updateHeatingAutomation", { espId: esp.id, automationStatus: automationStatus }).then((result) => setUserEsp(result));
    };

    return (
        <div>
            <Form className="automation-form">
                <Form.Group controlId="automationStatusHeating" className="mb-4">
                    <div className="d-flex align-items-center ms-2">
                        <Form.Check
                            type="switch"
                            id="automation-status-switch"
                            label="Smart uppvärmning aktiverad"
                            checked={esp?.heating?.automationStatus}
                            onChange={toggleAutomation}
                            className="mb-0 d-flex align-items-center"
                        />
                        <i className="bi bi-info-circle ms-2" onClick={handleShowHelp} style={{ cursor: 'pointer', color: '#007bff' }} />
                    </div>
                </Form.Group>
            </Form>

            <Modal show={showHelp} onHide={handleCloseHelp} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Smart uppvärmning</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Genom att slå av denna inställning inaktiveras den automatiska styrningen och värmepumpens egen styrning används istället.
                </Modal.Body>
            </Modal>
        </div >
    )
}

export default AutomationStatus;


