import { Form, Col, Row, Modal } from "react-bootstrap";
import React, { useState, useContext } from "react";
import { AuthContext } from "../../AuthProvider";
import '../../routes/heating.css';
import Parse from "parse";

const TempSettings = () => {

    const { esp, updateUserEsp, setUserEsp } = useContext(AuthContext);

    const [showHelp, setShowHelp] = useState(false);

    const handleCloseHelp = () => setShowHelp(false);

    const handleShowHelp = () => setShowHelp(true);

    const handleAwayTempInput = (event) => {
        const newValue = parseFloat(event.target.value);
        const oldValue = esp.heating?.tempSettings?.away?.temp || 15;
        if (newValue > -1 && newValue < 31 && newValue <= oldValue + 1 && newValue >= oldValue - 1) {
            update(esp.id, { heating: { tempSettings: { away: { temp: newValue } } } });
        }
    };

    const handleMinTempInput = (event) => {
        const newValue = parseFloat(event.target.value);
        const oldValue = esp.heating?.tempSettings?.min?.temp || 18;
        if (newValue > -1 && newValue < 31 && newValue <= oldValue + 1 && newValue >= oldValue - 1) {
            const updates = {
                min: { temp: newValue }
            };

            // Om min blir högre än ideal, justera ideal
            if (newValue > esp.heating.tempSettings.ideal.temp) {
                updates.ideal = { temp: newValue };

                // Om ideal (nu samma som min) blir högre än max, justera max
                if (newValue > esp.heating.tempSettings.max.temp) {
                    updates.max = { temp: newValue };
                }
            }

            update(esp.id, { heating: { tempSettings: updates } });
        }
    };

    const handleIdealTempInput = (event) => {
        const newValue = parseFloat(event.target.value);
        const oldValue = esp.heating?.tempSettings?.ideal?.temp || 20;
        if (newValue > -1 && newValue < 31 && newValue <= oldValue + 1 && newValue >= oldValue - 1) {
            const updates = {
                ideal: { temp: newValue }
            };

            // Om ideal blir lägre än min, justera min
            if (newValue < esp.heating.tempSettings.min.temp) {
                updates.min = { temp: newValue };
            }

            // Om ideal blir högre än max, justera max
            if (newValue > esp.heating.tempSettings.max.temp) {
                updates.max = { temp: newValue };
            }

            update(esp.id, { heating: { tempSettings: updates } });
        }
    };

    const handleMaxTempInput = (event) => {
        const newValue = parseFloat(event.target.value);
        const oldValue = esp.heating?.tempSettings?.max?.temp || 23;
        if (newValue > -1 && newValue < 31 && newValue <= oldValue + 1 && newValue >= oldValue - 1) {
            const updates = {
                max: { temp: newValue }
            };

            // Om max blir lägre än ideal, justera ideal
            if (newValue < esp.heating.tempSettings.ideal.temp) {
                updates.ideal = { temp: newValue };

                // Om ideal (nu samma som max) blir lägre än min, justera min
                if (newValue < esp.heating.tempSettings.min.temp) {
                    updates.min = { temp: newValue };
                }
            }

            update(esp.id, { heating: { tempSettings: updates } });
        }
    };

    const update = (espId, updates) => {
        updateUserEsp(espId, updates)
            .then((result) => {
                Parse.Cloud.run("updateHeatingSchedule", { espId: esp.id })
                    .then((scheduleResult) => {
                        setUserEsp(scheduleResult)
                    });
            });
    }

    return (
        <div>
            <Form className="temp-form border-top mb-4">
                <div className="d-flex align-items-center mt-3">
                    <h5>Temperaturreglering</h5>
                    <i className="bi bi-info-circle ms-2 mb-2" onClick={handleShowHelp} style={{ cursor: 'pointer', color: '#007bff' }} />
                </div>
                <Row>
                    <Col sm={12} md={6} lg={3}>
                        <Form.Group controlId="awayTempRange">

                            <Form.Label>
                                Bortrest °C
                            </Form.Label>

                            <Form.Control
                                type="number"
                                className="temp-number-input"
                                disabled={esp?.heating?.automationStatus ? false : true}
                                min={0}
                                max={30}
                                step={0.1}
                                placeholder="Ange minsta temperatur när du är bortrest"
                                value={esp?.heating?.tempSettings?.away?.temp?.toFixed(1)}
                                onChange={handleAwayTempInput}
                            />
                        </Form.Group>
                    </Col>
                    <Col sm={12} md={6} lg={3}>
                        <Form.Group controlId="minTempRange">

                            <Form.Label>
                                Min °C
                            </Form.Label>

                            <Form.Control
                                type="number"
                                className="temp-number-input"
                                disabled={esp?.heating?.automationStatus ? false : true}
                                min={0}
                                max={30}
                                step={0.1}
                                placeholder="Ange minsta temperatur"
                                value={esp?.heating?.tempSettings?.min?.temp?.toFixed(1)}
                                onChange={handleMinTempInput}
                            />
                        </Form.Group>
                    </Col>
                    <Col sm={12} md={6} lg={3}>
                        <Form.Group controlId="idealTempRange">
                            <Form.Label>Ideal °C</Form.Label>
                            <Form.Control
                                type="number"
                                className="temp-number-input"
                                disabled={esp?.heating?.automationStatus ? false : true}
                                min={0}
                                max={30}
                                step={0.1}
                                placeholder="Ange medeltemperatur"
                                value={esp?.heating?.tempSettings?.ideal?.temp?.toFixed(1)}
                                onChange={handleIdealTempInput}
                            />
                        </Form.Group>
                    </Col>
                    <Col sm={12} md={6} lg={3}>
                        <Form.Group controlId="maxTempRange">
                            <Form.Label>Max °C</Form.Label>
                            <Form.Control
                                type="number"
                                className="temp-number-input"
                                disabled={esp?.heating?.automationStatus ? false : true}
                                min={0}
                                max={30}
                                step={0.1}
                                placeholder="Ange högsta temperatur"
                                value={esp?.heating?.tempSettings?.max?.temp?.toFixed(1)}
                                onChange={handleMaxTempInput}
                            />
                        </Form.Group>
                    </Col>
                </Row>
            </Form>
            <Modal show={showHelp} onHide={handleCloseHelp} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Temperaturreglering</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Här ställer du in ditt önskade spann för inomhustemperatur. Inom spannet min-max tillåts inomhusvärmen variera under dygnet. Den högsta värmen används för att buffra värme i huset vid lågt elpris för att sedan låta det sjunka till minsta temperaturen under de dyraste timmarna. Borta anger den minsta temperatur som skall hållas för att huset inte skall påverkas negativt av fukt eller frysskador när du är bortrest.
                </Modal.Body>
            </Modal>
        </div>

    )
}

export default TempSettings;


