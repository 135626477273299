import { Form, Modal } from "react-bootstrap";
import React, { useState, useContext } from "react";
import { AuthContext } from "../../AuthProvider";
import '../../routes/heating.css';
import Parse from "parse";

const AutomationStatus = () => {

    const { esp, updateUserEsp } = useContext(AuthContext);

    const [showHelp, setShowHelp] = useState(false);

    const handleCloseHelp = () => setShowHelp(false);

    const handleShowHelp = () => setShowHelp(true);

    // Skicka kommando för att slå på/av automation
    const toggleAutomation = () => {
        updateUserEsp(esp.id, {hotWater: {automationStatus: !esp.hotWater?.automationStatus}})
            .then(() => {
                return Parse.Cloud.run("evaluateHotWater", {
                    espId: esp.id,
                });
            })
            .catch(error => {
                console.error("Fel vid uppdatering av varmvattenstatus:", error);
            });
    };

    return (
        <div>
            <Form className="automation-form">
                <Form.Group controlId="automationStatusWater" className="mb-4">
                    <div className="d-flex align-items-center ms-2">
                        <Form.Check
                            type="switch"
                            id="automation-status-switch"
                            label="Smart vattenvärmning aktiverad"
                            checked={esp.hotWater?.automationStatus}
                            onChange={toggleAutomation}
                            className="mb-0 d-flex align-items-center"
                        />
                        <i className="bi bi-info-circle ms-2" onClick={handleShowHelp} style={{ cursor: 'pointer', color: '#007bff' }} />
                    </div>
                </Form.Group>
            </Form>

            <Modal show={showHelp} onHide={handleCloseHelp} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Smart vattenvärmning</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Om denna styrning slås av kommer värmepumpens egen logik användas för varmvattnet och det kommer värmas konstant oavsett elpris.
                </Modal.Body>
            </Modal>
        </div >
    )
}

export default AutomationStatus;


