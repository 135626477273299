import { NavLink, Outlet } from "react-router-dom";
import React, { useState } from 'react';
import logo from "../../minicitytransparent.png"

export default function LoginMenu() {

    const [menuOpen, setMenuOpen] = useState(false);

    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    }

    const closeMenu = () => {
        setMenuOpen(false);
    };

    return (
        <>
            <div id="sidebar" className="sidebar d-none d-md-block">
                <nav>
                    <NavLink to="/"><img src={logo} className="App-logo" alt="logo" /></NavLink>
                    <ul>
                        <li>
                            <NavLink
                                to="/login" end
                                className={({ isActive, isPending }) =>
                                    isActive
                                        ? "active"
                                        : isPending
                                            ? "pending"
                                            : ""
                                }>
                                <i className="bi bi-box-arrow-in-right" />
                                Logga in
                            </NavLink>
                        </li>
                        {/* Måste fixa smtp-server för att dessa två skall fungera korrekt */}
                        {/* <li>
                            <NavLink
                                to="/login/signup"
                                className={({ isActive, isPending }) =>
                                    isActive
                                        ? "active"
                                        : isPending
                                            ? "pending"
                                            : ""
                                }>
                                <i className="bi bi-person-fill-add" />
                                Skapa konto
                            </NavLink>
                        </li>
                        <li>
                            <NavLink
                                to="/login/requestreset"
                                className={({ isActive, isPending }) =>
                                    isActive
                                        ? "active"
                                        : isPending
                                            ? "pending"
                                            : ""
                                }>
                                <i className="bi bi-question-lg" />
                                Glömt lösenord
                            </NavLink>
                        </li> */}
                    </ul>
                </nav>
            </div>
            <div id="detail" className="detail">
                <button className="menu-toggle d-md-none" onClick={toggleMenu}>
                    <i className="bi bi-list" />
                </button>
                <Outlet />
            </div>

            {menuOpen && (
                <div className="mobile-menu d-md-none">
                    <nav>
                        <ul>
                            <li>
                                <NavLink
                                    to="/login" onClick={closeMenu} >
                                    <i className="bi bi-box-arrow-in-right" />
                                    Logga in
                                </NavLink>
                            </li>
                        </ul>
                    </nav>
                </div>
            )}

        </>
    );
}