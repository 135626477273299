import React, { useContext, useState, useEffect } from 'react';
import { format } from 'date-fns';
import { Modal } from "react-bootstrap";
import {
  ComposedChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Cell,
  Line,
  ReferenceLine,
} from 'recharts';
import { AuthContext } from "../../AuthProvider";
import Parse from 'parse';


const HotWaterSchedule = () => {
  const [showHelp, setShowHelp] = useState(false);
  const { esp } = useContext(AuthContext);
  const schedule = esp?.hotWater?.heatingPlan ?? [];
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const handleCloseHelp = () => {
    setShowHelp(false);
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await Parse.Cloud.run("getPrices");
        setData(result);
      } catch (error) {
        console.error("Fel vid hämtning av data:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-[400px]">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  const hasValidSchedule = Array.isArray(schedule) && schedule.length > 0;

  const consolidatedData = Object.values(data.reduce((acc, price) => {
    try {
      const priceDate = new Date(price.time);
      const now = new Date();
      const currentHour = new Date(now.getFullYear(), now.getMonth(), now.getDate(), now.getHours());

      // Skippa om timmen har passerat
      if (priceDate < currentHour) {
        return acc;
      }

      const hour = format(priceDate, 'HH:mm');
      const dateTime = format(priceDate, 'yyyy-MM-dd HH:mm');
      const fullDate = format(priceDate, 'd MMM HH:mm');

      const scheduleEntry = schedule.find(entry =>
        format(new Date(entry.hour), 'yyyy-MM-dd HH:mm') === format(priceDate, 'yyyy-MM-dd HH:mm')
      );

      if (!acc[dateTime] || price.price > acc[dateTime].price) {
        acc[dateTime] = {
          hour,
          fullDate,
          price: Number(price?.price) || 0,
          calculatedTemperature: scheduleEntry?.calculatedTemperature,
          isHeating: scheduleEntry?.isHeating || false,
          startTemp: scheduleEntry?.startTemp,
          endTemp: scheduleEntry?.endTemp,
          heatingMinutes: scheduleEntry?.heatingMinutes,
          targetTemp: scheduleEntry?.targetSchedule?.temp,
          scheduledTimes: scheduleEntry?.scheduledTimes,
          awayEnds: scheduleEntry?.awayEnds
        };
      }

      return acc;
    } catch (error) {
      console.error("Error processing price:", error);
      return acc;
    }
  }, {})).sort((a, b) => new Date(a.hour) - new Date(b.hour));
  /*console.log("consolidatedData: " + JSON.stringify(consolidatedData));
  console.log(consolidatedData
    .filter(entry => entry.scheduledTimes && entry.scheduledTimes.length > 0)
    .map((entry, index) => (
      entry.fullDate
    )));*/

  return (
    <>
      <div>
        <div className="d-flex align-items-center mt-3">
          <h5>Planerad värmning</h5>
          <i className="bi bi-info-circle ms-2 mb-2" onClick={() => setShowHelp(true)} style={{ cursor: 'pointer', color: '#007bff' }} />
        </div>

        {esp?.awayEnds && esp.awayEnds > new Date() && (<div className="mb-3 p-3 border rounded bg-light">
          <div className="mb-2">
            <i className="bi bi-luggage-fill me-2"></i>
            <span className="text-primary">Bortaläge slutar {format(new Date(esp.awayEnds), 'd MMM HH:mm')}</span>
          </div>
        </div>)}

        <div style={{ width: '100%', height: '400px' }}>
          <ResponsiveContainer width="75%" height="75%">
            <ComposedChart
              data={consolidatedData}
              margin={{
                top: 40,
                right: 50,
                left: 20,
                bottom: 5,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis
                dataKey="fullDate"
                tick={{ fontSize: 12 }}
                interval={2}
                allowDuplicatedCategory={false}
                tickFormatter={(value) => value.split(' ')[2]}
              />
              <YAxis
                yAxisId="left"
                label={{
                  value: 'Pris (kr/kWh)',
                  angle: -90,
                  position: 'insideLeft'
                }}
              />
              <YAxis
                yAxisId="right"
                orientation="right"
                label={{
                  value: 'Temperatur (°C)',
                  angle: 90,
                  position: 'insideRight'
                }}
                domain={[20, 60]}
              />
              <Tooltip
                content={({ active, payload, label }) => {
                  if (active && payload && payload.length) {
                    const currentIndex = consolidatedData.findIndex(item => item.fullDate === label);
                    const currentEntry = consolidatedData[currentIndex];
                    const prevEntry = currentIndex > 0 ? consolidatedData[currentIndex - 1] : null;

                    return (
                      <div className="custom-tooltip bg-white p-2 border border-gray-200 shadow-sm">
                        <p className="mb-1">{currentEntry?.fullDate}</p>
                        {payload.map((pld, index) => {
                          if (pld.name === "Temperatur") {
                            return (
                              <p
                                key={index}
                                className="mb-0"
                                style={{ color: '#801818' }}
                              >
                                {prevEntry?.calculatedTemperature
                                  ? `${prevEntry.calculatedTemperature.toFixed(1)}°C → ${currentEntry?.calculatedTemperature?.toFixed(1)}°C`
                                  : `${currentEntry?.calculatedTemperature?.toFixed(1)}°C`
                                }
                              </p>
                            );
                          }
                          return (
                            <p key={index} className="mb-0" style={{ color: '#666' }}>
                              {pld.name}: {pld.value.toFixed(2)} kr/kWh
                            </p>
                          );
                        })}
                        {currentEntry?.scheduledTimes?.map((time, index) => (
                          <p key={index} className="mb-0" style={{ color: '#2E8B57' }}>
                            Schemalagd tid: {format(new Date(time), 'HH:mm')}
                          </p>
                        ))}
                        {currentEntry?.awayEnds && (
                          <p className="mb-0" style={{ color: '#007bff' }}>
                            Bortaläge slut: {format(new Date(currentEntry.awayEnds), 'HH:mm')}
                          </p>
                        )}
                      </div>
                    );
                  }
                  return null;
                }}
              />
              <Legend
                payload={[
                  { value: 'Elpris', type: 'rect', color: '#bfbfbf' },
                  { value: 'Planerad värmning', type: 'rect', color: '#FFB405' },
                  { value: 'Temperatur', type: 'line', color: '#801818' }
                ]}
              />

              <Bar
                yAxisId="left"
                dataKey="price"
                name="Elpris"
                fill="#bfbfbf"
              >
                {consolidatedData.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={hasValidSchedule && entry.isHeating ? '#FFB405' : '#bfbfbf'}
                  />
                ))}
              </Bar>
              {hasValidSchedule && (
                <Line
                  yAxisId="right"
                  type="monotone"
                  dataKey="calculatedTemperature"
                  name="Temperatur"
                  stroke="#801818"
                  dot={false}
                  strokeWidth={2}
                />
              )}
              {consolidatedData
                .filter(entry => entry.scheduledTimes && entry.scheduledTimes.length > 0)
                .map((entry, index) => (
                  <ReferenceLine
                    key={`ref-${index}`}
                    x={entry.fullDate}
                    yAxisId="left"
                    stroke="red"
                    strokeDasharray="3 3"
                  />
                ))}
              {consolidatedData
                .filter(entry => entry.awayEnds)
                .map((entry, index) => (
                  <ReferenceLine
                    key={`ref-${index}`}
                    x={entry.fullDate}
                    yAxisId="left"
                    stroke="black"
                    strokeDasharray="3 3"
                  />
                ))}
            </ComposedChart>
          </ResponsiveContainer>
        </div>
      </div >
      <Modal show={showHelp} onHide={handleCloseHelp} centered>
        <Modal.Header closeButton>
          <Modal.Title>Tider för varmvatten</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          De gula staplarna visar när värmning av vatten planeras. Den röda linjen är den uppskattade temperaturen på varmvattnet. En uppskattad sänkning vid förbrukning av vatten räknas bort vid de schemalagda klockslagen utifrån tidigare användardata. Schemat räknas om när temperaturen ändras.
        </Modal.Body>
      </Modal>
    </>
  );
};

export default HotWaterSchedule; 
