import React from 'react';
import GrafanaDashboard from "../components/statistics/GrafanaDashboard"

const Home = () => {
    return (
        <div className="detail-container">
            <div className="lightform">
            <GrafanaDashboard></GrafanaDashboard>
            </div>
        </div>
    )
}

export default Home;


