import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import Parse from "parse";

const ERROR_MESSAGES = {
    125: "Felaktigt format på e-postadress",
    203: "E-postadressen används redan, prova att logga in istället"
};

const Signup = () => {
    const navigate = useNavigate();

    const [email, setEmail] = useState('')
    const [phone, setPhone] = useState('')
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [name, setName] = useState('');

    const onSubmit = async (e) => {
        e.preventDefault()
        if (password !== confirmPassword) {
            toast.error("Lösenorden matchar inte")
            return;
        }
        if(password.length < 6){
            toast.error("Lösenordet är för kort, det skall vara minst 6 tecken")
            return;
        }
        const user = new Parse.User();
        user.set("username", email);
        user.set("password", password);
        user.set("email", email);
        user.set("name", name);
        user.set("phone", phone);
        await user.signUp()
            .then(async (userCredential) => {
                // Signed in
                navigate("/");
            })
            .catch((error) => {
                console.log(JSON.stringify(error));
                const message = error.code
                    ? ERROR_MESSAGES[error.code]
                    : undefined;

                toast.error(message ? message : "Ett okänt fel har uppstått");
            });
    }

    return (
        <div className="detail-container">
            <div className="loginform lightform">
                <div className="logininput">
                    <label htmlFor="name">
                        Namn
                    </label>
                    <input
                        type="name"
                        label="Namn"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        required
                        placeholder="Namn"
                    />
                </div>
                <div className="logininput">
                    <label htmlFor="email-address">
                        E-post
                    </label>
                    <input
                        type="email"
                        label="E-post"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                        placeholder="E-post"
                    />
                </div>

                <div className="logininput">
                    <label htmlFor="phone">
                        Telefonnummer
                    </label>
                    <input
                        type="phone"
                        label="Telefonnummer"
                        value={phone}
                        onChange={(e) => setPhone(e.target.value)}
                        required
                        placeholder="Telefonnummer"
                    />
                </div>

                <div className="logininput">
                    <label htmlFor="password">
                        Lösenord
                    </label>
                    <input
                        type="password"
                        label="Skapa lösenord"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                        placeholder="Lösenord"
                    />
                </div>
                <div>
                    <label htmlFor="confirmPassword">
                        Bekräfta lösenord
                    </label>
                    <input
                        type="password"
                        label="Bekräfta lösenord"
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        required
                        placeholder="Bekräfta lösenord"
                    />
                </div>

                <div className="logininput">
                    <button className="loginbutton"
                        type="submit"
                        onClick={onSubmit}
                        disabled={email && password && name ? false : true}
                    >
                        Skapa konto
                    </button>
                </div>
            </div>
        </div>

    )
}

export default Signup;