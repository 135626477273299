import { Form, Modal, Button } from "react-bootstrap";
import React, { useState, useContext } from "react";
import { AuthContext } from "../../AuthProvider";
import './HotWaterStart.css';
import Parse from "parse";

const HotWaterStart = () => {
    const [showHelp, setShowHelp] = useState(false);
    const { esp, updateUserEsp } = useContext(AuthContext);

    const handleCloseHelp = () => {
        setShowHelp(false);
    }

    const toggle = () => {
        updateUserEsp(esp.id, { hotWater: { heatNow: !esp.hotWater?.heatNow } })
            .then(() => {
                return Parse.Cloud.run("evaluateHotWater", {
                    espId: esp.id,
                });
            })
            .catch(error => {
                console.error("Fel vid uppdatering av varmvattenstatus:", error);
            });
    };

    return (
        <>
            <div className="mt-3">
                <Form className="temp-form border-top mb-4">
                    <div className="d-flex align-items-center mt-3">
                        <h5>Värm vatten nu</h5>
                        <i className="bi bi-info-circle ms-2 mb-2" onClick={() => setShowHelp(true)} style={{ cursor: 'pointer', color: '#007bff' }} />
                    </div>
                    <p>Nuvarande varmvattentemp: {esp?.sensorData?.hotWaterTemp}°C.{esp?.hotWater?.heatNow ? " Värmning pågår." : ""}</p>
                    <Button onClick={toggle} variant={esp?.hotWater?.heatNow ? "danger" : "primary"} disabled={esp?.hotWater?.automationStatus ? false : true}>
                        {esp?.hotWater?.heatNow ? "Stoppa värmning" : "Starta värmning"} {/* Bootstrap plus icon */}
                    </Button>
                </Form>

            </div>
            <Modal show={showHelp} onHide={handleCloseHelp} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Starta och stoppa varmvattenproduktion</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Med smart styrning av vattenvärmning är värmningen avstängd som standard och värmer vid lågt elpris, antingen en gång per dygn eller utifrån angivna tidpunkter då varmt vatten önskas. Klicka här för att tillfälligt värma vattnet omedelbart oavsett elpris.
                </Modal.Body>
            </Modal>
        </>

    )
}

export default HotWaterStart;


