import React from 'react';
import ChangePassword from '../components/account/ChangePassword';
import UserSettings from '../components/account/UserSettings';

const Account = () => {

    return (
        <div className="detail-container">
            <ChangePassword/>
            <UserSettings/>
        </div>
    )
}

export default Account;