import React, { useState, useContext } from "react";
import { Form, Button, Collapse, Modal } from "react-bootstrap";
import { AuthContext } from "../../AuthProvider";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import toast from "react-hot-toast";
import Parse from 'parse';

const AwayTimes = () => {

    const [open, setOpen] = useState(false);

    const [showHelp, setShowHelp] = useState(false);

    const handleCloseHelp = () => {
        setShowHelp(false);
    }

    const { esp, updateUserEsp, setUserEsp } = useContext(AuthContext);

    const saveSelectedDate = (date) => {
        const now = new Date();
        if (date > now) {
            updateUserEsp(esp.id, { awayEnds: date })
                .then(Parse.Cloud.run("updateHotWaterHours", { espId: esp.id })
                    .then(Parse.Cloud.run("updateHeatingSchedule", { espId: esp.id })
                        .then((result) => setUserEsp(result))));
        } else {
            toast.error("Planerad hemkomst måste vara i framtiden.");
        }

    }

    const clearDate = () => {
        updateUserEsp(esp.id, { awayEnds: null })
            .then(Parse.Cloud.run("updateHotWaterHours", { espId: esp.id })
                .then(Parse.Cloud.run("updateHeatingSchedule", { espId: esp.id })
                    .then((result) => setUserEsp(result))));
    };

    return (
        <>
            <div className="card p-3 mb-3">
                <div className="d-flex justify-content-between align-items-center" onClick={() => setOpen(!open)}>
                    <div>
                        <h5>Bortaläge {esp?.awayEnds ? " på" : " av"}</h5>
                    </div>
                    <i
                        className={open ? "bi bi-caret-up" : "bi bi-caret-down"}
                        style={{ cursor: "pointer" }}
                    />
                </div>
                <Collapse in={open}>
                    <div className="mt-3">
                        <Form>
                            <Form.Group controlId="dateTimePicker">
                                <div className="d-flex align-items-center mt-3">
                                    <h5>Bortaläge</h5>
                                    <i className="bi bi-info-circle ms-2 mb-2" onClick={() => setShowHelp(true)} style={{ cursor: 'pointer', color: '#007bff' }} />
                                </div>
                                Planerad hemkomst:&nbsp;
                                <DatePicker
                                    selected={esp?.awayEnds}
                                    onChange={(date) => saveSelectedDate(date)}
                                    showTimeSelect
                                    timeFormat="HH:mm"
                                    timeIntervals={15}
                                    dateFormat="yyyy-MM-dd HH:mm"
                                    placeholderText="Välj datum och tid"
                                    className="form-control"
                                />
                                &nbsp;
                                {esp?.awayEnds && (
                                    < Button variant="danger" onClick={clearDate}>
                                        Avsluta bortaläge
                                    </Button>
                                )}
                            </Form.Group>
                        </Form>

                    </div>
                </Collapse >
            </div >
            <Modal show={showHelp} onHide={handleCloseHelp} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Bortaläge</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Ställ in datum för hemkomst för att starta bortaläge och spara på el medan du är iväg. Värmen kommer hållas på nivån inställd under "bortrest" och varmvattnet kommer vara avstängt. Värme och varmvatten kommer att startas igång för att värmas vid billigast möjliga tillfälle och vara redo när du kommer hem.
                </Modal.Body>
            </Modal>
        </>
    );
};

export default AwayTimes;
