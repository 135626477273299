import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom'
import toast from "react-hot-toast";
import { useLocation } from 'react-router-dom';
import Parse from "parse";

const ERROR_MESSAGES = {
    "": "",
};

const ResetPassword = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [token, setToken] = useState(null);

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const token = queryParams.get('token');
        setToken(token);
    }, [location]);


    const onSubmit = (e) => {
        e.preventDefault();

        if (token) {
            if (newPassword !== confirmPassword) {
                toast.error("Lösenorden matchar inte")
                return;
            }
            Parse.User.become(token)
                .then((user) => {
                    user.setPassword(newPassword);
                    user.save().then(() => {
                    toast.success("Lösenordet har blivit återställt");
                    navigate('/login');
                })
                })
                .catch((error) => {
                    console.log("errorcode: " + error.code);
                    console.log("error: " + JSON.stringify(error));
                    const message = error.code
                        ? ERROR_MESSAGES[error.code.replace(/\//g, ".")]
                        : undefined;

                    toast.error(message ? message : "Ett okänt fel har uppstått");
                });
        } else {
            toast.error("Ej korrekt länk");
        }

    }

    return (
        <div className="nomenu-container">
            <div className="loginform lightform">
                <div className="logininput">
                    <label htmlFor="newPassword">
                        Nytt lösenord
                    </label>
                    <input
                        type="password"
                        label="Nytt lösenord"
                        value={newPassword}
                        onChange={(e) => setNewPassword(e.target.value)}
                        required
                        placeholder="Nytt lösenord"
                    />
                    <label htmlFor="confirmPassword">
                        Bekräfta lösenord
                    </label>
                    <input
                        type="password"
                        label="Bekräfta lösenord"
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        required
                        placeholder="Bekräfta lösenord"
                    />
                </div>

                <div className="logininput">
                    <button className="loginbutton"
                        onClick={onSubmit}
                        disabled={newPassword && confirmPassword ? false : true}
                    >
                        Återställ lösenord
                    </button>
                </div>
            </div>
        </div>
    )
}

export default ResetPassword;