import React, { useContext, useState } from 'react';
import toast from "react-hot-toast";
import { Form, Button, Collapse } from "react-bootstrap";
import { AuthContext } from "../../AuthProvider";
import Parse from "parse";

const ERROR_MESSAGES = {
  "101": "Fel nuvarande lösenord",
};

const ChangePassword = () => {
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [open, setOpen] = useState(false);

  const {user} = useContext(AuthContext);

  const changePassword = async (e) => {
    e.preventDefault();
    if (newPassword !== confirmPassword) {
      toast.error("Lösenorden matchar inte")
      return;
    }
    if (inputIsValid(oldPassword, newPassword)) {
      
      if(!user){
        toast.error("Du måste vara inloggad för att ändra lösenord.");
        return;
      }

      await Parse.User.logIn(user.get("username"), oldPassword)
        .then(async () => {
          user.set("password", newPassword);
          await user.save().then((result) => {
            toast.success("Lösenordet uppdaterades");
            setOpen(false);
          });
          setOldPassword("");
          setNewPassword("");
          setConfirmPassword("");
        })
        .catch((error) => {
          const message = error.code
            ? ERROR_MESSAGES[error.code]
            : undefined;
          toast.error(message ? message : "Ett okänt fel har uppstått");
          setOldPassword("");
          setNewPassword("");
          setConfirmPassword("");
        });
    }
  };

return (
  <div className="card p-3 mb-3">
    <div className="d-flex justify-content-between align-items-center" onClick={() => setOpen(!open)}>
        <div>
          <h5>Ändra lösenord</h5>
        </div>
      <i
        className={open ? "bi bi-caret-up" : "bi bi-caret-down"}
        style={{ cursor: "pointer" }}
      />
    </div>
    <Collapse in={open}>
      <div className="mt-3">
        <Form>
          <Form.Group controlId="formOldPassword">
            <Form.Label>Nuvarande lösenord</Form.Label>
            <Form.Control
              type="password"
              value={oldPassword}
              onChange={(e) => setOldPassword(e.target.value)}
            />
          </Form.Group>
          <Form.Group controlId="formNewPassword" className="mt-2">
            <Form.Label>Nytt lösenord</Form.Label>
            <Form.Control
              type="password"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
            />
          </Form.Group>
          <Form.Group controlId="formConfirmPassword" className="mt-2">
            <Form.Label>Bekräfta nytt lösenord</Form.Label>
            <Form.Control
              type="password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
          </Form.Group>
          <Button variant="primary" className="mt-3" onClick={changePassword} disabled={oldPassword && newPassword && confirmPassword ? false : true}>
            Ändra lösenord
          </Button>
        </Form>
      </div>
    </Collapse>
  </div>
);
};

function inputIsValid(oldPassword, newPassword) {
  if (!oldPassword) {
    toast.error("No previous password given");
    return false;
  }
  if (!newPassword) {
    toast.error("No new password given");
    return false;
  } else if (newPassword.length < 6) {
    toast.error("New password must be at least 6 characters");
    return false;
  }
  return true;
}

export default ChangePassword;
